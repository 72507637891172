<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-card>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end m-2">
            <b-button
              v-b-modal.modal-center
              variant="primary"
            >
              <span class="text-nowrap">Add Tag</span>
            </b-button>
            <!-- modal Add Tag -->
            <b-modal
              id="modal-center"
              #default="{ hide }"
              centered
              title="Add Tag"
              hide-footer
            >
              <b-form
                class="p-2"
                @submit.enter.prevent=""
              >
                <b-form-group
                  label="Tag"
                  label-for="tag"
                >
                  <b-form-input
                    id="tag"
                    v-model="tagl"
                    autofocus
                    placeholder="Tag"
                  />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click="add()"
                  >
                    Add
                  </b-button>
                  <b-button
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    Cancel
                  </b-button>
                </div>
              </b-form>
            </b-modal>
          </div>
          <b-table
            ref="tags"
            :items="tagsData"
            :fields="tableColumns"
            responsive
          >
            <template #cell(id)="data">
              <span> # {{ data.item.id }} </span>
            </template>
            <template #cell(name)="data">
              <span> {{ data.item.tag_name }}</span>
            </template>
            <template
              #cell(actions)="data"
            >
              <b-button
                v-b-modal="'modal-edit'"
                variant="outline-primary"
                class="btn-icon m-1"
                md
                user="'data.item.id'"
                @click="sendInfo(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                class="btn-icon"
                md
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </template>
          </b-table>
          <!-- Edit -->
          <b-modal
            id="modal-edit"
            #default="{ hide }"
            centered
            title="Edit Tag"
            hide-footer
          >
            <b-form
              class="p-2"
              @submit.enter.prevent=""
            >
              <b-form-group
                label="Edit"
                label-for="edit-tag"
              >
                <b-form-input
                  id="edit-tag"
                  v-model="selectedTag.tag_name"
                  autofocus
                />
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  @click="add()"
                >
                  Save
                </b-button>
                <b-button
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </b-modal>
        </b-col>
      </b-card>
    </b-row>
  </div>
</template>

<script>

import {
  BCard, BCol, BRow, BTable, BButton, VBModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive,
} from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import others from '../others'
import otherModule from '../OtherModule'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selectedTag: '',
    }
  },
  setup() {
    const OTHER_MODULE = 'othermodule'

    // Register module
    if (!store.hasModule(OTHER_MODULE)) store.registerModule(OTHER_MODULE, otherModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OTHER_MODULE)) store.unregisterModule(OTHER_MODULE)
    })

    const tagsData = ref([])
    const formData = reactive({
      tag_name: null,
    })

    const fetchTags = () => {
      store.dispatch('othermodule/getTags').then(res => {
        console.log(res.data)
        tagsData.value = res.data
        return res.data
      })
    }
    fetchTags()

    const { tableColumns, tags } = others()

    return {
      tags,
      fetchTags,
      tagsData,
      tableColumns,
      formData,
    }
  },
  methods: {
    sendInfo(id) {
      this.selectedUser = id
    },
  },
}
</script>
